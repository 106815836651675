import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import VideoGrid from "../components/modules/video-grid"

export default ({ data }) => {
  //highlight-line
  const page = data.wpPage
  const robots = {
    name: `robots`,
    content: `${page.seo.metaRobotsNoindex}, ${page.seo.metaRobotsNofollow}`,
  }
  return (
    <Layout highContrast>
      <SEO
        title={page.seo.title}
        description={page.seo.metaDesc}
        meta={[robots]}
        schema={page.seo.schema?.raw}
      />
      <VideoGrid icon="►" videos={page.media} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      seo {
        ...SEO
      }
      media {
        title
        subtitle
        videos {
          html
        }
      }
      id
      uri
      template {
        templateName
      }
      title
    }
  }
`
